import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { getQueryParams, setQueryParams } from "react-use-query-param-string";

import { Accordion, Badge, Button } from "react-bootstrap";

import Layout from "../components/layout";
import { Spinner } from "react-bootstrap";
import _ from "lodash";
import { components } from "../types/projects";
import CopyContent from "../components/copy-content";

interface Props {
  location: Location;
}

const Report = ({ location }: Props) => {
  const params = getQueryParams();
  const [loading, setLoading] = useState(false);
  const [sportWalesWIPSChecked, setSportWalesWIPSChecked] = useState(false)
  const [WIPSChecked, setWIPSChecked] = useState(false)
  const [sportWalesChecked, setSportWalesChecked] = useState(false)

  const [startDate, setStartDate] = useState(
    new Date("01/01/2022").toLocaleDateString("en-CA")
  );
  const [endDate, setEndDate] = useState(
    new Date("12/31/2022").toLocaleDateString("en-CA")
  );
  const [dataToCopy, setDataToCopy] = useState();

  const getReport = () => {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    setLoading(true);
    fetch(`/api/report/start/${start}/end/${end}?type=${WIPSChecked && 'WIPS,'}&type=${sportWalesWIPSChecked && 'Sport Wales and WIPS,'}&type=${sportWalesChecked && 'Sport Wales,'}`)
      .then((response) => response.json())
      .then((report) => {
        setDataToCopy(
          report.length > 0
            ? report.map(
              (item) =>
                `Project Title: ${item["Project Title"]}
Individuals Involved: ${[...new Set(item["Individuals Involved"])]}
Progress in last quarter: ${item["Progress in last quarter"]}
Current Status: ${item["Current Status"]}

`
            )
            : "No results"
        );
        setLoading(false);
      });
  };
  // useEffect(() => {
  //   // setStatus(searchParams.has("status") && searchParams.get("status"));
  //   // searchParams.set("status", "Test")

  //   if (loading) {

  // }, [loading]);

  return (
    <Layout mainClassName="container-fluid">
      <div className="row">
        <h1 className="block-title">
          <FormattedMessage
            id="report_generate"
            defaultMessage="Generate a report"
          />
        </h1>
        <p>
          This will generate a report from projects with a start date and end
          date that you choose below. Please make sure your projects have a
          start and end date.
        </p>
      </div>
      {loading ? (
        <div className="row">
          <div className="col">
            <h2 className="anchor-title">
              <FormattedMessage id="loading" />{" "}
              <FormattedMessage id="my_proposals_and_projects" />
            </h2>
            <Spinner animation="border" variant="secondary" role="status">
              <span className="visually-hidden">
                <FormattedMessage id="loading" />
              </span>
            </Spinner>
          </div>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-6">
                      <Form.Group className="mb-3" controlId="startDate">
                        <Form.Label>
                          {" "}
                          <FormattedMessage
                            id="start_date"
                            defaultMessage="Start Date"
                          />
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </div>
                    <div className="col-6">
                      <Form.Group className="mb-3" controlId="endDate">
                        <Form.Label>
                          {" "}
                          <FormattedMessage
                            id="end_date"
                            defaultMessage="End Date"
                          />
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-9 col-sm-12 mt-3">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" name="types" id="allProjects" value="Sport Wales" checked={sportWalesChecked} onChange={() => setSportWalesChecked(!sportWalesChecked)}/>
                        <label className="form-check-label" htmlFor="allProjects">Sport Wales Projects</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" name="types" id="sportWalesWIPSProjects" checked={sportWalesWIPSChecked} value="Sport Wales & WIPS supported projects" onChange={() => setSportWalesWIPSChecked(!sportWalesWIPSChecked)}/>
                        <label className="form-check-label" htmlFor="sportWalesWIPSProjects">Sport Wales & WIPS supported projects</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" name="types" id="WIPSProjects" value="WIPS Projects" checked={WIPSChecked} onChange={() => setWIPSChecked(!WIPSChecked)}/>
                        <label className="form-check-label" htmlFor="WIPSProjects">WIPS Projects</label>
                      </div>

                    </div>
                    <div className="col-md-3 mt-3 d-flex">
                      <button className="btn btn-secondary b" onClick={getReport}>
                        Generate Report
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <pre
                contentEditable
                onBlur={(t) => setDataToCopy(t.currentTarget.innerText)}
              >
                {dataToCopy}
              </pre>
              <CopyContent content={dataToCopy} />
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Report;
