import React, { useState } from "react";

interface Content {
	content: String
}

export default function CopyContent({ content }: Content) {
	const [copied, setCopied] = useState(false);

	const copyContent = () => {
		navigator.clipboard.writeText(content)

		setCopied(true)

		setTimeout(() => {
			setCopied(false)
		}, 2200);
	}

	return (
		<>
			{/* {JSON.stringify(content)} */}
			<button className="btn btn-secondary" onClick={copyContent}>{copied ? 'Copied!' : 'Copy to clipboard'}</button>
		</>
	)
}